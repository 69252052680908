import React, { useEffect, useLayoutEffect } from 'react';
import { Link, PageProps } from 'gatsby';
import { Flex, Display, Para, Button } from 'workspace-core-ui';
import styled from 'styled-components';
import css from '@styled-system/css';
import { useAppSelector, useAppDispatch } from '@hooks/redux-hooks';
import Layout from '@containers/Layout';
import {
  startNewSession,
  startNewPairShareSession,
} from '@slices/loggingSlice';
import useTranslation from '@hooks/useTranslation';
import BodyWrapper from '@components/BodyWrapper';
import { resetGame, setCurrentLanguage } from '@slices/gameStateSlice';
import CustomMdxRenderer from '@containers/CustomMdxRenderer';
import Seo from '@containers/Seo';
import AnimatedIntroImageWrapper from '@components/AnimatedIntroImageWrapper';
import getSymbol from '@utils/getSymbol';
import useUserLocalLanguage from '@hooks/useUserLocalLanguage';
import BackgroundImage from '@components/BackgroundImage';
import { WINDOW_HASH } from '@sharedConstants';
import useHasMounted from '@hooks/useHasMounted';
import DeactivationModal from '@containers/DeactivationModal';
import gameConfig from '@content/gameconfig.json';
import { Module } from 'types';
import { ModuleOption } from './module-option';
import { Profile } from '../module/profile';
import { resetProfile, setProfile } from '../module/profile-slice';

const StartButton = styled(Button).attrs({
  forwardedAs: Link,
})(
  css({
    alignSelf: 'center',
    textDecoration: 'none',
  }),
);

const CenterContentWrapper = styled(Flex)(
  css({
    isolation: 'isolate',
    mt: 2,
    flex: [0.2, 1, 1],
    flexDirection: 'column',
  }),
);

const BottomContentWrapper = styled(Flex)(
  css({
    mt: 4,
    isolation: 'isolate',
    flex: 1,
    flexDirection: 'column',
    alignSelf: 'center',
  }),
);

function isDeactivated(version: string): boolean {
  const code = version.replace('#', '');
  return new Set([
    'sc5ze8',
    '8njgrr',
    'idfmut',
    'o0acqg',
    'l87p07',
    '49pmvm',
    '0l01zx',
    'pif7yw',
    'twdm4u',
    'gcdnd0',
    'kn9zva',
    'h8wxs6',
    '62ef96',
    'un0rrl',
    'dvjzj2',
    'pbp1di',
    '18f004',
    'kb3ox4',
    'mwo49x',
    '4nph2e',
    'tzbhjt',
    'sbb6dl',
    '9cb4b4',
    'dhx8py',
    'yxnfrj',
    '0v6658',
    'i45c3k',
    'e3mr3k',
    's5k711',
    'a1jv55',
    '306ycw',
    'kurp59',
    'eju9aa',
    'URbr3D',
    '4try9n',
    '99ry3m',
    'vz074o',
    'tUPbgj',
    'utadjl',
    'w8t0Li',
    'qh94xf',
    'hb71an',
    '40vl0r',
    'btfs0f',
    'xuzqxi',
    '3971h5',
    'erbg7p',
    'fhz5f3',
    'QTCJ7n',
    'sdelj8',
    '8aby8k',
    'dw0tyd',
    'n15prr',
    'rfu9kt',
    '6mtc0s',
    'fwfuxi',
    '0x6uga',
    '504kul',
    's4o9f7',
    '56xd9u',
    '30lcjg',
    '86k01s',
    'k3odr7',
  ]).has(code);
}

export const HomePage = ({
  location,
  data,
}: PageProps<Queries.IndexPageQuery>): JSX.Element => {
  // this hook must always fire first, and we also need to an log event for language change
  // do do that we must wait until we have a session. Its a little redundant, but we have to trigger the setlang event again once we have a valid session (seen below)
  useUserLocalLanguage();
  const hasMounted = useHasMounted();
  // this must be valid (its a random str to signify session)
  const { sessionId, userId } = useAppSelector(state => state.logging);
  const { currentLanguage, defaultLanguage, isPairShareEnabled } =
    useAppSelector(state => state.gameState);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const mainPageTitleSymbol = getSymbol(data.mainPageTitleSymbol);
  const mainPageBgSymbol = getSymbol(data.mainPageBgSymbol);
  const modules: Module[] = gameConfig.Modules;
  useEffect(() => {
    // a safety precaution for if you pressed back midgame and ended up here, or reset after finishing
    dispatch(resetGame());
    // first thing is to provision a session for the user
    // this should happen each time
    if (isPairShareEnabled === true) {
      dispatch(startNewPairShareSession({ language: currentLanguage }));
    } else {
      dispatch(startNewSession({ language: currentLanguage }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isPairShareEnabled]);

  useEffect(() => {
    dispatch(resetProfile());
    const profile = Profile.getProfile(userId || '');
    if (profile) {
      dispatch(setProfile({ profile: profile.tags }))
    }
  }, [dispatch, userId]);
  useLayoutEffect(() => {
    // this is so we can send the lang change event once we gain a session (if applicable)
    if (sessionId) {
      // do session contingent stuff here, like setting a users language if it is mismatched from default due to hook
      if (currentLanguage !== defaultLanguage) {
        dispatch(
          setCurrentLanguage({
            setTo: currentLanguage,
            eventType: 'auto_toggle_lang',
          }),
        );
      }
    }
    // we really do not want this to double fire whenever language changes, we only care when session id changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultLanguage, dispatch, sessionId]);

  return (
    <Layout
      shouldAnimate={false}
      location={location}
      sx={{
        backgroundColor: 'primary',
      }}
    >
      {mainPageBgSymbol && (
        <BackgroundImage
          imageData={mainPageBgSymbol.data}
          imageType={mainPageBgSymbol.type}
        />
      )}
      <BodyWrapper overflow="hidden">
        <Flex flex={1} />
        {isDeactivated(WINDOW_HASH) && (
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          <DeactivationModal isOpen onClose={() => { }} />
        )}
        <CenterContentWrapper>
          {mainPageTitleSymbol ? (
            <AnimatedIntroImageWrapper
              overrideImageStyling={{
                mb: 5,
              }}
              imageData={mainPageTitleSymbol.data}
              imageType={mainPageTitleSymbol.type}
            />
          ) : (
            <Flex flex={0.5} />
          )}
          <Display mt={2} fontWeight={900} variant="gameTitle">
            <CustomMdxRenderer>{t('Main Title', true)}</CustomMdxRenderer>
          </Display>
          <Para as="div" mt={2} px={5} variant="p2" isBold={false}>
            <CustomMdxRenderer>{t('Main Tagline', true)}</CustomMdxRenderer>
          </Para>
        </CenterContentWrapper>
        <BottomContentWrapper>
          {/* when we ssg or have no sessionId, we want to display a loading spinner */}
          {hasMounted && sessionId ? (
            <Flex flexDirection="column" alignItems="center">
              {modules
                .sort((a, b) => a.Index - b.Index)
                .map(module => (
                  <ModuleOption replayMode={false} module={module} key={module.Index} />
                ))}
            </Flex>
          ) : (
            <Flex flexDirection="column" alignItems="center">
              <Flex
                sx={{
                  '--spinner-color': e => e.colors.text,
                  '--spinner-width': ['60px', '60px', '80px'],
                  '--spinner-height': ['60px', '60px', '80px'],
                }}
                className="lds-ring"
              >
                <div />
                <div />
                <div />
                <div />
              </Flex>
              {/* this is done bc at this junction we have no react to work with */}
              <StartButton
                variant="secondary"
                className="fade-in"
                to="javascript:window.location.reload()"
              >
                {t('Reload')}
              </StartButton>
            </Flex>
          )}
        </BottomContentWrapper>
      </BodyWrapper>
    </Layout>
  );
};

export const Head = () => <Seo />;
